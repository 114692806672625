@tailwind components;
@tailwind utilities;

@import '@molecula-monorepo/tailwind-config-shared/theme.css';

body {
    color: rgb(var(--color-text-primary));
    background: rgb(var(--color-bg-primary));
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
